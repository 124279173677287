import React from 'react';

import { AccountHero, AccountLayout } from '../../components/account/common';
import { AccountForgetPasswordForm } from '../../components/account/auth';

const AccountForgetPasswordPage = (): JSX.Element => (
  <AccountLayout
    allowAnonymous
    hero={<AccountHero disableBackground title="Forget password?" />}
  >
    <AccountForgetPasswordForm />
  </AccountLayout>
);

export default AccountForgetPasswordPage;
